.GridApp{
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 90vh auto;
}

@media (max-width:800px) {
    .GridApp{
        display: flex;
        flex-direction: column;
    }

    .navabar{
        display: flex;
        flex-direction: row;
    }

    .Content{
        margin: 2.5vw 5vw 5vw 5vw !important;
        padding: 0 !important;
    }
}

.header{
    grid-column: 1 / 3;
}

.footer{
    grid-column: 1 / 3;
}

.button{
    color: #FFFFFF;
    border: none;
    background-color: #24D898;
    border-radius: 10px;
    transition: 0.5s all ease-in-out;
    box-shadow: rgb(0 0 0 / 50%) 0px 5px 10px;
    padding: 10px 20px;
    cursor: pointer;
} 

.buttonTall{
    margin: 0 auto;
    color: #FFFFFF;
    border: none;
    background-color: #24D898;
    border-radius: 10px;
    transition: 0.5s all ease-in-out;
    box-shadow: rgb(0 0 0 / 50%) 0px 5px 10px;
    padding: 10px 20px;
    cursor: pointer;
    width: 50%;
    text-align: center;
} 

.buttonDiv{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
    justify-items: stretch;
}

.buttonOff{
    display: none;
} 

.Content{
    margin: 5vw 5vw 5vw 2.5vw;
    display: flex;
    flex-direction: column;
    gap: 2.5vw;
    overflow: auto;
    padding: 10px;
}

.inputDiv {
    position: relative;
    border-bottom: 2px solid #24D898;
    margin: 15px 0;
}

.inputDiv input {
    width: 100%;
    padding: 0 5px;
    height: 40px;
    border: none;
    background: none;
    color: white;
    outline: none;
}

.inputDiv select {
    width: 100%;
    padding: 0 5px;
    height: 40px;
    border: none;
    background: none;
    color: white;
    outline: none;
}

.inputDiv option {
    color: white;
    background-color: rgba(38, 38, 38);
}

.inputDiv label {
    display: flex;
    gap: 10px;
    position: absolute;
    top: 50%;
    left: 5px;
    color: white;
    transform: translateY(-50%);
    pointer-events: none;
    transition: .5s;
}

.inputDiv span::before {
    content: '';
    position: absolute;
    top: 40px;
    left: 0;
    width: 0%;
    height: 2px;
    background: #24D898;
    transition: .5s;
}

.inputDiv input:focus ~ label,
.inputDiv input:valid ~ label {
    top: -10px;
    color: #24D898;
}

.inputDiv input:focus ~ span::before,
.inputDiv input:valid ~ span::before {
    width: 100%;
}

.inputDiv select:focus ~ label,
.inputDiv select:valid ~ label {
    top: -5px;
    color: #24D898;
}

.inputDiv select:focus ~ span::before,
.inputDiv select:valid ~ span::before {
    width: 100%;
}

.inputDiv input[type=date] ~ label,
.inputDiv input[type=date] ~ label {
    top: -5px;
    color: #24D898;
}

.inputDiv input[type=date] ~ span::before,
.inputDiv input[type=date] ~ span::before {
    width: 100%;
}

.radioDiv{
    display: flex;
    flex-direction: row !important ;
    align-items: center;
}

.checkboxDiv{
    display: flex;
    gap: 10px;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-end;
}

input[type=radio] {
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    margin: 10px;
    font: inherit;
    width:1.25em;
    height: 1.25em;
    border-radius: 50%;
    transition: 0.5s all ease-in-out;
    border: 2px solid #24D898 !important ;
    display: grid;
    place-content: center;
}

input[type=radio]::before {
    content: "";
    width: 1em;
    height: 1em;
    border-radius: 50%;
    transform: scale(0);
    transition: 0.5s all ease-in-out;
    box-shadow: inset 1em 1em #24D898;
    
}
  
input[type=radio]:checked::before {
    transform: scale(1);
}

input[type=radio]:hover::before {
    box-shadow: inset 1em 1em #24D898 ;
}

input[type=checkbox] {
    appearance: none;
    margin: 10px;
    font: inherit;
    width: 1.25em;
    height: 1.25em;
    /* border-radius: 5px; */
    transition: 0.5s all ease-in-out;
    border: 2px solid #24D898 !important;
    display: grid;
    place-content: center;
}

input[type=checkbox]::before {
    content: "";
    width: 0.75em;
    height: 0.75em;
    transform: scale(0);
    transition: 0.5s all ease-in-out;
    box-shadow: inset 1em 1em #24d898;
}
  
input[type=checkbox]:checked::before {
    transform: scale(1);
}

input[type=checkbox]:hover::before {
    box-shadow: inset 1em 1em #24D898 ;
}