
.VehicleDiv{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.VehicleDiv h2{
    padding-bottom: 20px;
    border-bottom: 2px solid #24D898;
}

.VehicleContent{
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 2px solid #24D898;
    padding: 20px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: rgba(38, 38, 38, 0.5);
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 75%);
}

.VehicleGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: center;
}

.VehicleGrid p{
    padding: 10px;
}

.VehicleGrid button{
    grid-column: 1/3 ;
}