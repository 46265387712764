.ProfilDiv{
    display: flex;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 75%);
    flex-direction: column;
    gap: 20px;
    background-color: rgba(38, 38, 38, 0.5);
}

.ProfilDiv h2{
    padding-bottom: 20px;
    border-bottom: 2px solid #24D898;
}

.ProfilGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.ProfilGrid p{
    padding: 10px;
}
