.header {
    display: flex;
    padding: 20px 5vw;
    background-color: #262626;
    justify-content: space-between;
    align-items: center;
}

.header img{
    height: 50px;
}
