.Login{
    background-color: rgba(38, 38, 38, 0.5);
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 50%) 0px 10px 20px;
    padding: 30px;
}

.Login button{
    margin-top: 10px;
}

.Login form{
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.Login #icon{
    width: 25%;
    margin: auto;
}