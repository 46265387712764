.Notif {
    position: fixed;
    bottom: 5vw;
    left: 5vw;
    background-color: #262626;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    z-index: 4;
    transition: all 0.25s ease-in-out;
    max-width: 0;
    overflow: hidden;
}

.success{
    padding: 10px 20px;
    border: #26CD9A 2px solid;
    max-width: initial !important;
}

.error{
    padding: 10px 20px;
    border: #26CD9A 2px solid;
    max-width: initial !important;
}


