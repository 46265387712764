.ErorDiv{
    background-color: rgba(38, 38, 38, 0.5);
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 50%) 0px 10px 20px;
    padding: 30px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#Eror{
    width: 50%;
}