.ServiceDiv{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ServiceDiv h2{
    padding-bottom: 20px;
    border-bottom: 2px solid #24D898;
}

.ServiceContent{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 75%);
    background-color: rgba(38, 38, 38, 0.5);
}

.ServiceFlex{
    border: 2px solid #24D898;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.ServiceGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: center;
}

.ServiceGrid p{
    padding: 10px;
}

