.navbar {
    display: flex;
    padding: 20px;
    background-color: #262626;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin: 5vw 2.5vw 5vw 5vw;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 75%);
}

.navbar div{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 50px;
    flex-direction: column;
}

@media (max-width:800px) {

    
    .navbar{
        display: flex;
        flex-direction: row;
        margin: 5vw 5vw 2.5vw 5vw !important;
    }

    .navbar div{
        display: flex;
        flex-direction: row;
    }

}